/** @jsx jsx */

import React from 'react';
import { jsx, Image } from 'theme-ui';
import SEO from '../components/seo';
import Layout from '../components/layout';

import './styles.scss';
import moonalgoRocket from '../assets/images/moonalgo-rocket.webp';

export default function ThankYou() {
  return (
    <Layout>
      <SEO title="" noIndex={true} />
      <div className="page-container-sm">
        <div class="mt-2 mb-2">
          <h2>Thank you!</h2>
          <h4 class="mb-1">Your transaction has been successfully processed.</h4>
          You're now on your way to unlocking the full potential of MoonAlgo!{' '}
          <br />
          Expect a welcome email to hit your inbox soon, packed with all the
          instructions you need to get started. <br />
          In the meantime, feel free to explore our{' '}
          <a
            target="_blank"
            href="https://guide.moonalgo.com/onboarding-with-moonalgo"
          >
            Onboard Guide page
          </a>{' '}
          for additional information on how to get started with MoonAlgo.
          <br /><br />
          We're thrilled to have you join us and are here to support you every
          step of the way. 
          <br /><br />Happy trading!
          <h4>- MoonAlgo Team</h4>
        </div>

        <div>
          <Image
            style={{ borderRadius: '12px', margin: '0 auto', display: 'block' }}
            src={moonalgoRocket}
            alt="MoonAlgo Rocket"
          />
        </div>
      </div>
    </Layout>
  );
}
